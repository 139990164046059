<template>
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-md-8">
                <div class="card">
                    <div class="card-header">Halaman Home</div>

                    <div class="card-body">
                        <p>Lorem ipsum dolor sit, amet consectetur adipisicing elit. Nobis corrupti veniam eligendi necessitatibus natus quia! Quod error exercitationem consequuntur iure odit nesciunt ullam distinctio eius, adipisci, nulla inventore molestias dolorum?</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        mounted() {
            console.log('Component mounted.')
        }
    }
</script>
