<template>
    <div>
        <Navbar v-if="token && router !== '/registration'"></Navbar>
        <Sidebar v-if="token && router !== '/registration'"></Sidebar>
        <div class="content-wrapper" v-if="token">
            <router-view></router-view>
        </div>
        <aside class="control-sidebar control-sidebar-dark" v-if="token">
            <div class="p-3">
                <a class="btn btn-sm btn-danger btn-delete" @click="logout()">Logout</a>
            </div>
        </aside>
        <Footer v-if="token && router !== '/registration'"></Footer>
        <Login v-if="!token && router !== '/registration'"></Login>
        <RegistrationProperty v-if="router === '/registration'"></RegistrationProperty>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                token : {},
                router : ''
            }
        },

        created() {
            this.token  = localStorage.getItem('token');
            this.router = this.$router.currentRoute.path
        },

        methods: {
            async logout() {
                window.axios.defaults.headers.common['Authorization'] = `Bearer ${this.token}`
                try {
                    let save = await axios.post('/api/logout-admin')
                    if (save.data.status) {
                        //remove localStorage
                        localStorage.removeItem('token')
                        //redirect
                        location.reload();
                        // return this.$router.push({name : "Login"})
                    }
                } catch (error) {
                    console.log(error);
                }
            }
        }
    }
</script>