<template>
    <div>
        <div class="container-fluid mt-4">
            <div class="row">
            <!-- left column -->
            <div class="col-md-2"></div>
            <div class="col-md-8">
                <!-- general form elements -->
                <div class="card card-primary">
                <div class="card-header" style="background-color: #ffd600; color: black;">
                    <h3 class="card-title">Form Registration RENT2OWN</h3>
                </div>
                <!-- /.card-header -->
                <!-- form start -->
                <form role="form">
                    <div class="card-body">
                        <div class="form-group">
                            <label for="exampleInputEmail1">Name</label>
                            <input type="text" class="form-control" placeholder="Enter name" v-model="input.name">
                        </div>
                        <div class="form-group">
                            <label for="exampleInputEmail1">Email</label>
                            <input type="email" class="form-control" placeholder="Enter email" v-model="input.email">
                        </div>
                        <div class="form-group">
                            <label for="exampleInputEmail1">Password</label>
                            <input type="password" class="form-control" placeholder="Enter password" v-model="input.password">
                        </div>
                        <div class="form-group">
                            <label for="exampleInputEmail1">Phone</label>
                            <input type="number" class="form-control" placeholder="Enter phone" v-model="input.phone">
                        </div>
                        <div class="form-group">
                            <label for="exampleInputEmail1">Province</label>
                            <input type="text" class="form-control" placeholder="Enter province" v-model="input.province">
                        </div>
                        <div class="form-group">
                            <label for="exampleInputEmail1">City</label>
                            <input type="text" class="form-control" placeholder="Enter city" v-model="input.city">
                        </div>
                        <div class="form-group">
                            <label for="exampleInputEmail1">District</label>
                            <input type="text" class="form-control" placeholder="Enter district" v-model="input.district">
                        </div>
                        <div class="form-group">
                            <label for="exampleInputEmail1">Address</label>
                            <input type="text" class="form-control" placeholder="Enter address" v-model="input.address">
                        </div>
                    </div>
                    <!-- /.card-body -->

                    <div class="card-footer">
                        <a class="btn btn-warning">Submit</a>
                    </div>
                </form>
                </div>
                <!-- /.card -->
            </div>
            <div class="col-md-2"></div>
            </div>
            <!-- /.row -->
        </div><!-- /.container-fluid -->
    </div>
</template>

<script>
export default {
    data() {
        return {
            input : {},
        }
    },

    // methods: {
    //     async pushUserProperty(){
    //         try {
    //             let inputUser   = this.input
    //             let save = await axios.post('/api/backend/user-property/save', inputUser)
    //             if (save.data.status) {
    //                 location.reload();
    //                 alert("registration success");
    //                 // this.$router.push({
    //                 //     name : "thank-you",
    //                 // })
    //             }
    //         } catch (error) {
    //             console.log(error);
    //         }
    //     },
    // }
}
</script>