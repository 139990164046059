require('./bootstrap');

import Vue from "vue";
import VueRouter from "vue-router";
import Index from "./Index";
import RegistrationProperty from "./Registration";
import RegistrationRent from "./RegistrationRent";
import Login from "./components/pages/Login";
import router from "./routes";

// admin-lte
import $ from 'jquery';
window.Vue = require('vue');
window.$ = window.jQuery = $;

import ExampleComponent from "./components/ExampleComponent.vue"
import Navbar from "./components/Navbar.vue"
import Sidebar from "./components/Sidebar.vue"
import Footer from "./components/Footer.vue"



Vue.use(VueRouter);
Vue.component('examplecomponent', ExampleComponent);
Vue.component('pagination', require('laravel-vue-pagination'));
Vue.component('Navbar', Navbar);
Vue.component('Sidebar', Sidebar);
Vue.component('Footer', Footer);
Vue.component('Login', Login);
Vue.component('RegistrationProperty', RegistrationProperty);


const app = new Vue({
    el: '#app',
    router,
    components: {
        Index: Index,
        registrationproperty: RegistrationProperty,
        registrationrent: RegistrationRent
    }
});