<template>
    <div class="login-box">
        <div class="login-logo">
            <a href="#">PROPERTY.CO.ID</a>
        </div>
    <!-- /.login-logo -->
        <div class="card">
            <div class="card-body login-card-body">
                <form>
                    <div class="input-group mb-3">
                    <input type="email" class="form-control" placeholder="Email" v-model="inputLogin.email">
                        <div class="input-group-append">
                            <div class="input-group-text">
                            <span class="fas fa-envelope"></span>
                            </div>
                        </div>
                    </div>
                    <div class="input-group mb-3">
                        <input type="password" class="form-control" placeholder="Password" v-model="inputLogin.password">
                        <div class="input-group-append">
                            <div class="input-group-text">
                            <span class="fas fa-lock"></span>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                    <!-- /.col -->
                    <div class="col-4">
                        <button type="button" class="btn btn-primary btn-block" @click="login()">Login</button>
                    </div>
                    <!-- /.col -->
                    </div>
                </form>
            </div>
            <!-- /.login-card-body -->
        </div>
    </div>
</template>

<script>

export default {
  name: "SignUp",

  data() {
        return {
            inputLogin : {
                email : '',
                password : ''
            },
            errors: {}
        }
    },

    methods: {
        async login(){
            try {
                let save = await axios.post('/api/login-admin', this.inputLogin)
                console.log(save.data.status)
                if (save.data.status) {
                    let userData = save.data.data
                    localStorage.setItem('token', userData.token)
                    this.$router.push("dashboard")
                    location.reload();
                }else{
                    alert("login failed, user is not exist!")
                    return save.data
                }
            } catch (error) {
                console.log(error);
            }
        },
    }
};
</script>
