<template>
    <div class="content">
        <div class="content-header">
            <div class="container-fluid">
                <div class="row mb-2">
                <div class="col-sm-6">
                    <h1 class="m-0 text-dark">Form</h1>
                </div>
                <div class="col-sm-6">
                    <ol class="breadcrumb float-sm-right">
                    <li class="breadcrumb-item">
                        <router-link :to="{name: 'user-property'}">
                            Form User Property
                        </router-link>
                    </li>
                    <li class="breadcrumb-item active">User</li>
                    </ol>
                </div>
                </div>
            </div>
        </div>
        <div class="container-fluid mt-4">
            <div class="row">
            <!-- left column -->
            <div class="col-md-2"></div>
            <div class="col-md-8">
                <!-- general form elements -->
                <div class="card card-primary">
                <div class="card-header" style="background-color: #ffd600; color: black;">
                    <h3 class="card-title">Form User Property</h3>
                </div>
                <!-- /.card-header -->
                <!-- form start -->
                <form role="form">
                    <div class="card-body">
                        <div class="form-group">
                            <label for="exampleInputEmail1">Name</label>
                            <input type="text" class="form-control" placeholder="Enter name" v-model="user.name">
                        </div>
                        <div class="form-group">
                            <label for="exampleInputEmail1">Email</label>
                            <input type="email" class="form-control" placeholder="Enter email" v-model="user.email">
                        </div>
                        <div class="form-group">
                            <label for="exampleInputEmail1">Phone</label>
                            <input type="number" class="form-control" placeholder="Enter phone" v-model="user.phone">
                        </div>
                        <div class="form-group">
                            <label for="exampleInputEmail1">Province</label>
                            <input type="text" class="form-control" placeholder="Enter province" v-model="userAddress.province">
                        </div>
                        <div class="form-group">
                            <label for="exampleInputEmail1">City</label>
                            <input type="text" class="form-control" placeholder="Enter city" v-model="userAddress.city">
                        </div>
                        <div class="form-group">
                            <label for="exampleInputEmail1">District</label>
                            <input type="text" class="form-control" placeholder="Enter district" v-model="userAddress.district">
                        </div>
                        <div class="form-group">
                            <label for="exampleInputEmail1">Address</label>
                            <input type="text" class="form-control" placeholder="Enter address" v-model="userAddress.address">
                        </div>
                    </div>
                    <!-- /.card-body -->

                    <div class="card-footer">
                        <a class="btn btn-warning" @click="editUserProperty()">Submit</a>
                    </div>
                </form>
                </div>
                <!-- /.card -->
            </div>
            <div class="col-md-2"></div>
            </div>
            <!-- /.row -->
        </div><!-- /.container-fluid -->
    </div>
</template>

<script>
export default {
    data() {
        return {
            user : {},
            userAddress : {},
            token: localStorage.getItem('token')
            // isEdit : false,
        }
    },

    created() {
        this.fetchDataUserPropertyById();
    },

    methods: {
        async fetchDataUserPropertyById(){
            window.axios.defaults.headers.common['Authorization'] = `Bearer ${this.token}`
            const url   = window.location.href;
            const split = url.split('/');
            const id    = split[5]
            try {
                axios.get(`/api/backend/user-property/edit/${id}`)
                .then(response => {
                    this.user        = response.data;
                    this.userAddress = this.user.user_address;
                });
            } catch (error) {
                console.log(error)
            }
        },

        async editUserProperty(){
            window.axios.defaults.headers.common['Authorization'] = `Bearer ${this.token}`
            const url   = window.location.href;
            const split = url.split('/');
            const id    = split[5]
            try {
                let editUser   = this.user
                let save = await axios.post(`/api/backend/user-property/update/${id}`, editUser)
                console.log(save)
                if (save.data.status) {
                    this.$router.push({
                        name : "user-property",
                    })
                }
            } catch (error) {
                console.log(error);
            }
        },
    }
}
</script>