import VueRouter from "vue-router";
import ExampleComponent from "./components/ExampleComponent";
import Home from "./components/Home";
import Dashboard from "./components/pages/dashboard/Dashboard";
import User from "./components/pages/users/UserProperty";
import EditUserProperty from "./components/pages/users/Edit";

const routes = [{
        path: "/backend",
        component: Dashboard,
        name: "dashboard",
    },
    {
        path: "/backend/user-property",
        component: User,
        name: "user-property"
    },
    {
        path: "/backend/user-property-edit/:id",
        component: EditUserProperty,
        name: "user-property-edit",
    },
];

const router = new VueRouter({
    routes, // short for `routes: routes`
    mode: 'history',
});

router.beforeEach((to, from, next) => {
    let token = localStorage.getItem('token');
    if (to.path !== "/login" && to.path !== "/registration" && !token) next({ path: "login" });
    if (to.path === "/login" && token) next({ name: "dashboard" });
    else next();
});

export default router;