<template>
  <div>
    <!-- Content Header (Page header) -->
    <div class="content-header">
      <div class="container-fluid">
        <div class="row mb-2">
          <div class="col-sm-6">
            <h1 class="m-0 text-dark">User Property</h1>
          </div>
          <div class="col-sm-6">
            <ol class="breadcrumb float-sm-right">
              <li class="breadcrumb-item"><a href="#">User Property</a></li>
              <li class="breadcrumb-item active">User Property</li>
            </ol>
          </div>
        </div>
      </div>
    </div>
    <!-- /.content-header -->
    <div class="container-fluid">
        <div class="card">
            <div class="card-header">
                <h3 class="card-title">DATA</h3>
            </div>
            <!-- <div class="card-header">
                <router-link class="nav-link" :to="{name: 'form-user-property'}">
                    <button type="button" class="btn btn-success">Create</button>
                </router-link>
            </div> -->
            <!-- /.card-header -->
            <div class="card-body p-0">
                <table class="table table-condensed">
                    <thead>
                        <tr>
                            <th>#Id</th>
                            <th>Name</th>
                            <th>Email</th>
                            <th>Phone</th>
                            <th>Address</th>
                            <th>Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="data in userProperty" :key="data.id">
                            <td>#{{data.id}}</td>
                            <td>{{data.name}}</td>
                            <td>{{data.email}}</td>
                            <td>{{data.phone}}</td>
                            <td>{{data.user_address.address}}</td>
                            <td>
                                <router-link class="btn btn-sm btn-warning" :to="'/backend/user-property-edit/'+data.id">edit</router-link>
                                <a class="btn btn-sm btn-danger btn-delete" @click="deleteUserProperty(data.id)">Delete</a>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <!-- /.card-body -->
        </div>
    </div>
  </div>
</template>

<script>
export default {
    data() {
        return {
            userProperty : {},
            token: localStorage.getItem('token')
        }
    },

    mounted(){
        this.fetchDataUserProperty()
    },

    methods: {
        async fetchDataUserProperty(){
            window.axios.defaults.headers.common['Authorization'] = `Bearer ${this.token}`
            try {
                // let user = await axios.get('/api/backend/user-property?page=' + page);
                axios.get('/api/backend/user-property')
                .then(response => {
                    this.userProperty = response.data;
                });
            } catch (error) {
                console.log(error)
            }
        },

        async deleteUserProperty(id){
            window.axios.defaults.headers.common['Authorization'] = `Bearer ${this.token}`
            let confirmation = confirm("Are you sure to execute this action?")
            if (confirmation) {
                axios.delete(`/api/backend/user-property/delete/${id}`)
                .then(response => {
                    if (response.data.status) {
                        let i = this.userProperty.map(data => data.id).indexOf(id)
                        this.userProperty.splice(i, 1)
                    }
                });
            }
        }
    }
}
</script>